<template>
  <div class="container-fluid my-2">
    <div class="text-center" v-if="getUserOffers.length == 0 && !$apollo.queries.getUserOffers.loading">
      <h1>لم ترفع أي عرض حتى الان</h1>
    </div>
    <!-- <vue-good-table
      :columns="columns"
      :rows="getUserOffers"
      v-if="getUserOffers.length > 0"
      :rtl="true"
      :lineNumbers="true"
      styleClass="vgt-table striped bordered"
      :pagination-options="{
        enabled: true,
        nextLabel: 'التالي',
        prevLabel: 'السابق',
        rowsPerPageLabel: 'عدد الصفوف',
        ofLabel: 'من',
        pageLabel: 'صفحة',
      }"
      class="px-1"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <button
            class="btn btn-danger btn-sm"
            @click="cancelOffer($event, props.row)"
          >
            حذف العرض
          </button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table> -->

    <cards v-if="getUserOffers.length > 0" :rows="getUserOffers" :columns="columns" :cardSlots="cardSlots">
      <template #cardNumber="cardNumberProps">
        {{cardNumberProps.index}}
       </template>
      <template #Images="imagesProps">
        <div class="row justify-content-around">

          <img rel="preload" :src="'https://images.scrapiz.com/' + imagesProps.row.offers[0].images[0]"
            class="scrapiz-card-img-top img-thumbnail" style="height: 15rem" />

        </div>
      </template>

      <template #totalAmount="totalAmountProps">
        <div>
          <div class="row justify-content-around">
            <p class="col-5 m-auto">
              <slot>الإجمالي</slot>
            </p>
            <p class="col-5 m-auto">
              <slot> {{ totalAmountProps.row.offers[0].offerAmount + totalAmountProps.row.offers[0].shippingAmount }} ريال
              </slot>
            </p>
          </div>

        </div>
      </template>
      <template #actions="actionsProps">
        <button class="btn bwj-btn btn-md mb-2" @click="offerInfo(actionsProps.row._id,actionsProps.row.offers[0]._id)">
          التفاصيل
        </button>
        <button
        class="btn btn-danger btn-md mb-2 mx-2" @click="cancelOffer(actionsProps.row._id,actionsProps.row.offers[0]._id)">
        حذف العرض
        </button>
       
      </template>
    </cards>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { GET_OFFERS } from "../../graphql/queries";
import { CANCEL_OFFER } from "../../graphql/mutations";
import cards from '../cards/cards.vue';

export default {
  data() {
    return {
      showModal: false,
      selectedOffer: null,
      // columns: [
      //   {
      //     label: "الشركة المصنعة",
      //     field: "manufacturer",
      //   },

      //   {
      //     label: "القطعة",
      //     field: "name",
      //   },

      //   {
      //     label: "القيمة الإجمالية",
      //     field: this.total,
      //     formatFn: this.formatFn,
      //     type: "number",
      //   },
      //   {
      //     label: "الموقع",
      //     field: "location",
      //     sortable: false,
      //   },
      //   {
      //     label: " ",
      //     field: "actions",
      //     sortable: false,
      //   },
      // ],
      columns: [
        {
          key: ".name",
          label: "القطعة",
        },
        {
          key: ".model",
          label: "السيارة",
        },


      ],

      getOrders: [],
      cardSlots: {
        headerSlots: ["cardNumber"],
        topSlots: ["Images"],
        bottomSlots: ["totalAmount", "actions"]
      },

      getUserOffers: [],
    };
  },
  apollo: {
    getUserOffers: {
      query: GET_OFFERS,
      result(result) {
        console.log(result);
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    cards,
  },
  methods: {
    total(rowObj) {
      return rowObj.offers[0].shippingAmount + rowObj.offers[0].offerAmount;
    },
    formatFn(value) {
      return value + " ريال ";
    },
    acceptOffer(e, payload) {
      this.selectedOffer = payload._id;

      this.showModal = true;
    },
    cancelOffer(requestId,offerId) {

      if (confirm("هل تريد حذف هذا العرض؟")) {
        this.$apollo
          .mutate({
            // Query
            mutation: CANCEL_OFFER,
            // Parameters
            variables: {
              RequestId:requestId,
              OfferId: offerId,
            },
            update: (store, { data: { cancelOffer } }) => {
             
              // Read the data from our cache for this query.
              if (cancelOffer) {
                const data = store.readQuery({ query: GET_OFFERS });
                
                data.getUserOffers.splice(payload.originalIndex, 1);
                store.writeQuery({
                  query: GET_OFFERS,
                  data,
                });
              }
            },
          })
          .then((data) => {
            // Result
            // this.$store.state.glopal.selectedAddress = data.addAddress[0];
            this.$store.commit("glopal/isLoading", false);
            $("html, body").animate({ scrollTop: 0 }, "slow");

            if (data.data.cancelOffer) {
              this.$store.dispatch("alert/success", "تم حذف العرض");
              window.location.reload(true);
            } else {
              this.$store.dispatch("alert/error", " لا تستطيع حذف هذا العرض");
            }
          })
          .catch((error) => {
            // Error
            this.$store.commit("glopal/isLoading", false);
            this.$store.dispatch("alert/error", error);
            console.error(error);
          });
      }

      //this.$router.replace("FullOffer/" + payload._id+'/'+payload.offers[0]._id);
    },
    closeModal() {
      let acceptedOffer = {
        RequestId: this.RequestFields[0]._id,
        OfferID: this.selectedOffer,
      };
      this.$store
        .dispatch("glopal/acceptOffer", acceptedOffer)
        .then((response) => {
          console.log(response);
          this.$router.push("/Requests");
        });
    },
    offerInfo(requestId,offerId) {
 
      this.$router.push("/seller/fullOffer/" + requestId + "/"+offerId);

      return;
    },
  },
};
</script>

<style scoped></style>
